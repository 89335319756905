(function () {
	'use strict';
	angular.module('event').factory('fieldFormatting', [fieldFormatting]);

	function fieldFormatting() {
		return {
			formatDate: formatDate,
			formatDisplayNumber: formatDisplayNumber,
		};

		function formatDate(date, format) {
			var momentDate = date ? moment(date) : '';
			return momentDate && momentDate.isValid()
				? momentDate.format(format)
				: date;
		}

		function formatDisplayNumber(numberInput, options, preventRounding) {
			//If we are evaluating from no user input and the value is blank do nothing
			if (!numberInput && numberInput !== 0) {
				numberInput = '';
			}

			var originalNumberInput = numberInput;
			var formatas = options.formatas;
			var thousandsSeparator = options.thousandsSeparator;
			var decimalCharacter = options.decimalCharacter || '.';
			var decimalPlaces = options.decimalPlaces;
			var numberLabel =
				options.formatas === 'percent'
					? '%'
					: options.numberLabel || '';
			var numberLabelPosition =
				options.formatas === 'percent'
					? 'after'
					: options.numberLabelPosition || 'before';

			var negativeCharacter = '-';

			var negativeValue;
			var integerValue;
			var decimalValue;
			var formattedNumber;
			var number;
			var numberString;

			//Build regex to replace symbols that we will add back later
			var replaceSymbolRegexString =
				'[' +
				escapeRegExp(thousandsSeparator) +
				escapeRegExp(numberLabel) +
				']';
			var replaceSymbolRegexp = new RegExp(replaceSymbolRegexString, 'g');
			// var regexString = "(" + negativeCharacter + ")?(\\d+)" + "(\\" + decimalCharacter + "\\d{0," + decimalPlaces + "})?"; ///(-?\d+)(\.\d{0,2})?/

			//Build regex to extract the first number we find in the input
			var matchNumberString =
				'(' +
				escapeRegExp(negativeCharacter) +
				')?(\\d+)?' +
				'(' +
				escapeRegExp(decimalCharacter) +
				'\\d+)?'; ///(-?\d+)(\.\d{0,2})?/
			var matchNumberRegexp = new RegExp(matchNumberString);

			//Perform regex
			var numberParts = numberInput
				.toString()
				.replace(replaceSymbolRegexp, '')
				.match(matchNumberRegexp);
			//If the field is empty then we don't want to write zero we set an empty value
			if (!numberParts || numberParts[0] === '') {
				return {
					number: originalNumberInput,
					formattedNumber: originalNumberInput,
				};
			}

			numberString = numberParts[0];
			negativeValue = numberParts[1] || '';
			// integerValue = numberParts[2] || '0';
			// decimalValue = numberParts[3];

			number = Number(numberString);
			// decimalValue = pad(decimalValue, decimalPlaces + 1, '0');
			if (
				decimalPlaces !== '' &&
				decimalPlaces != null &&
				!preventRounding
			) {
				formattedNumber = round(number, decimalPlaces)
					.toFixed(decimalPlaces)
					.replace(negativeValue, '')
					.split(decimalCharacter);
			} else {
				formattedNumber = numberString
					.replace(negativeValue, '')
					.split(decimalCharacter);
			}

			integerValue = formattedNumber[0] || '0';
			decimalValue = formattedNumber[1]
				? decimalCharacter + formattedNumber[1]
				: '';

			//Add thousands separator to integer
			if (thousandsSeparator) {
				integerValue = addThousandsSeparator(
					integerValue,
					thousandsSeparator
				);
			}

			formattedNumber = integerValue + decimalValue;

			return {
				number: number,
				formattedNumber:
					numberLabelPosition === 'before'
						? negativeValue + numberLabel + formattedNumber
						: negativeValue + formattedNumber + numberLabel,
			};
		}

		function round(value, decimals) {
			return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
		}

		function addThousandsSeparator(numberString, separator) {
			//Function expects a string with no thousands separators in the string

			//apply formatting
			return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
		}

		function escapeRegExp(input) {
			if (!input) {
				return '';
			}
			return input.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
		}

		function pad(value, width, padchar) {
			while (value.length < width) {
				value += padchar;
			}
			return value;
		}
	}
})();
